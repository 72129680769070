import * as React from "react";
import { CreateButton, TopToolbar } from "react-admin";

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton label="Создать" />
  </TopToolbar>
);

export default ListActions;
