import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TabbedForm,
  TextInput,
  Create,
  required,
  ImageField,
  ImageInput,
  SaveButton,
  Toolbar,
  Labeled,
  FormTab,
} from "react-admin";

const GeneralPageTitle = ({ record }) => {
  return <span>Страница сайта {record ? `"${record.title}"` : ""}</span>;
};

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

export const GeneralPageList = () => (
  <List actions={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок" source="title" sortable={false} />
      <TextField
        label="Название раздела"
        source="sectionName"
        sortable={false}
      />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const GeneralPageEdit = (props) => (
  <Edit {...props} title={<GeneralPageTitle />}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="Элемент">
        <TextInput disabled source="id" />
        <TextInput label="Заголовок" validate={required()} source="title" />
        <TextInput
          label="Подзаголовок"
          validate={required()}
          source="subtitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Заголовок формы обратной связи"
          validate={required()}
          source="feedbackTitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Подзаголовок формы обратной связи"
          validate={required()}
          source="feedbackSubtitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Название раздела"
          validate={required()}
          source="sectionName"
        />
      </FormTab>
      <FormTab label="SEO">
        <TextInput
          label="Микроразметка. Заголовок"
          validate={required()}
          source="seoTitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Микроразметка. Описание"
          validate={required()}
          source="seoDescription"
          fullWidth
          multiline
        />
        <Labeled label="Микроразметка. Изображение">
          <ImageField source="seoImage" />
        </Labeled>
        <ImageInput
          label="Изменить изображение в микроразметке"
          source="seoImage"
          accept="image/*"
        >
          <ImageField source="seoImage" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const GeneralPageCreate = (props) => {
  return (
    <Create {...props} title="Create GeneralPage">
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput validate={required()} source="title" />
        <TextInput validate={required()} source="subtitle" fullWidth />
        <TextInput validate={required()} source="seoTitle" />
        <TextInput validate={required()} source="seoDescription" fullWidth />
        <ImageInput source="seoImage" accept="image/*">
          <ImageField source="seoImage" />
        </ImageInput>
        <TextInput validate={required()} source="feedbackTitle" />
        <TextInput validate={required()} source="feedbackSubtitle" fullWidth />
        <TextInput validate={required()} source="sectionName" />
      </SimpleForm>
    </Create>
  );
};
