import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  ReferenceInput,
  required,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import ListActions from "../core/ListActions";

const QuestionFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
];

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const QuestionTitle = ({ record }) => {
  return <span>F.A.Q. {record ? `"${record.title}"` : ""}</span>;
};

export const QuestionList = () => (
  <List filters={QuestionFilters} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Вопрос" source="question" sortable={false} />
      <TextField label="Ответ" source="answer" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const QuestionEdit = (props) => (
  <Edit {...props} title={<QuestionTitle />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput label="Вопрос" validate={required()} source="question" />
      <TextInput
        label="Ответ"
        validate={required()}
        source="answer"
        fullWidth
      />
      <ReferenceInput
        validate={required()}
        fullWidth
        source="rubricId"
        reference="Rubric"
        label="Rubric"
        filter={{ isSelect: true }}
      >
        <SelectInput label="Сфера" optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const QuestionCreate = (props) => {
  return (
    <Create {...props} title="Создать F.A.Q.">
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput label="Вопрос" validate={required()} source="question" />
        <TextInput
          label="Ответ"
          validate={required()}
          source="answer"
          fullWidth
        />
        <ReferenceInput
          validate={required()}
          fullWidth
          source="rubricId"
          reference="Rubric"
          label="Rubric"
          filter={{ isSelect: true }}
        >
          <SelectInput label="Сфера" optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
