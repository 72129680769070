/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { apiUrl } from "./apiCommon";

export default {

    login: ({username, password}) => 
    
    axios.post(`${apiUrl}/User/Login`, {username, password})
        .then(response => {
            localStorage.setItem('Bearer', response.data.token);
            localStorage.setItem('Expiration', response.data.expiration);
        }),
    logout: () => {
        localStorage.removeItem('Bearer');
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error.status === 401 || error.status === 403) {
            localStorage.removeItem('Bearer');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('Bearer')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
};