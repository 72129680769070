import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TabbedForm,
  TextInput,
  Create,
  SelectArrayInput,
  ReferenceArrayInput,
  required,
  Toolbar,
  SaveButton,
  FormTab,
} from "react-admin";

const MainPageTitle = ({ record }) => {
  return <span>Структура главной {record ? `"${record.title}"` : ""}</span>;
};

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

export const MainPageList = (props) => (
  <List {...props} actions={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Верхний заголовок" source="title" sortable={false} />
      <TextField
        label="Верхний подзаголовок"
        source="subtitle"
        sortable={false}
      />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const MainPageEdit = (props) => (
  <Edit {...props} title={<MainPageTitle />}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="Элемент">
        <TextInput disabled source="id" />
        <TextInput
          label="Верхний заголовок"
          validate={required()}
          source="title"
        />
        <TextInput
          label="Верхний подзаголовок"
          validate={required()}
          source="subtitle"
          fullWidth
        />
        <ReferenceArrayInput
          validate={required()}
          fullWidth
          source="mainBlockIds"
          reference="MainBlock"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput
            label="Блоки контента на главной"
            optionText="title"
          />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          validate={required()}
          fullWidth
          source="advantageIds"
          reference="Advantage"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput label="Преимущества" optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          validate={required()}
          source="projectIds"
          fullWidth
          reference="Project"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput label="Проекты" optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          validate={required()}
          source="menuPageIds"
          fullWidth
          reference="GeneralPage"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput label="Состав меню" optionText="title" />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const MainPageCreate = (props) => (
  <Create {...props} title="Create MainPage">
    <TabbedForm>
      <FormTab label="Элемент">
        <TextInput
          label="Верхний заголовок"
          validate={required()}
          source="title"
        />
        <TextInput
          label="Верхний подзаголовок"
          validate={required()}
          source="subtitle"
          fullWidth
        />
        <ReferenceArrayInput
          validate={required()}
          fullWidth
          source="mainBlockIds"
          reference="MainBlock"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput
            label="Блоки контента на главной"
            optionText="title"
          />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          validate={required()}
          fullWidth
          source="advantageIds"
          reference="Advantage"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput label="Преимущества" optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          validate={required()}
          source="projectIds"
          fullWidth
          reference="Project"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput label="Проекты" optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          validate={required()}
          source="menuPageIds"
          fullWidth
          reference="GeneralPage"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput label="Состав меню" optionText="title" />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
