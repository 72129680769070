import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageField,
  ImageInput,
  required,
  NumberInput,
  BooleanInput,
  Labeled,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import ListActions from "../core/ListActions";

const AdvantageFilters = [
  <BooleanInput
    source="isDel"
    label="Удаленное"
    alwaysOn
    defaultValue={false}
  />,
];

const AdvantageTitle = ({ record }) => {
  return <span>Плитка преимуществ {record ? `"${record.title}"` : ""}</span>;
};

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

export const AdvantageList = (props) => (
  <List
    title={
      'Плитки преимуществ (Выводятся на страницах "Главная", "О компании")'
    }
    filters={AdvantageFilters}
    {...props}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок" source="title" sortable={false} />
      <TextField label="Подзаголовок" source="subtitle" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const AdvantageEdit = (props) => (
  <Edit {...props} title={<AdvantageTitle />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput label="Заголовок" validate={required()} source="title" />
      <TextInput
        label="Подзаголовок"
        validate={required()}
        source="subtitle"
        fullWidth
      />
      <Labeled label="Изображение">
        <ImageField title="Изображение" label="Изображение" source="image" />
      </Labeled>
      <ImageInput label="Изменить изображение" source="image" accept="image/*">
        <ImageField source="image" />
      </ImageInput>
      <NumberInput label="Порядок отображения" source="mainOrder" />
    </SimpleForm>
  </Edit>
);

export const AdvantageCreate = (props) => (
  <Create {...props} title="Создать плитку преимуществ">
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput label="Заголовок" validate={required()} source="title" />
      <TextInput
        label="Подзаголовок"
        validate={required()}
        source="subtitle"
        fullWidth
      />
      <ImageInput
        label="Изображение"
        validate={required()}
        source="image"
        accept="image/*"
      >
        <ImageField label="Изображение" source="image" />
      </ImageInput>
      <NumberInput label="Порядок отображения" source="mainOrder" />
    </SimpleForm>
  </Create>
);
