import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TabbedForm,
  TextInput,
  Create,
  required,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  FormTab,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import ListActions from "../core/ListActions";
import { RichTextInput } from "ra-input-rich-text";

const RubricFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
];

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const RubricTitle = ({ record }) => {
  return <span>Рубрика {record ? `"${record.title}"` : ""}</span>;
};

export const RubricList = (props) => (
  <List filters={RubricFilters} {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок" source="title" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const RubricEdit = (props) => (
  <Edit {...props} title={<RubricTitle />}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="Элемент">
        <TextInput disabled source="id" />
        <TextInput label="Заголовок" validate={required()} source="title" />
        <TextInput
          label="Цвет (например, #BB6AB6)"
          validate={required()}
          source="hexColor"
        />
        <TextInput
          label="Alias ссылки рубрики"
          validate={required()}
          source="link"
        />
        <ReferenceInput
          validate={required()}
          fullWidth
          source="articlesPageId"
          reference="ArticlesPage"
          label="Раздел рубрики"
          filter={{ isSelect: true }}
        >
          <SelectInput
            label="Раздел рубрики"
            optionText="title"
            validate={required()}
          />
        </ReferenceInput>
        <RichTextInput
          label="Описание рубрики перед формой обратной связи"
          source="feedbackDescription"
          fullWidth
          multiline
        />
      </FormTab>
      <FormTab label="SEO">
        <TextInput
          label="Title в <head>"
          validate={required()}
          source="headTitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Description в <head>"
          validate={required()}
          fullWidth
          multiline
          source="headDescription"
        />
        <TextInput
          label="Заголовок h1"
          validate={required()}
          source="h1"
          fullWidth
          multiline
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const RubricCreate = (props) => (
  <Create {...props} title="Создать рубрику">
    <TabbedForm toolbar={<CreateToolbar />}>
      <FormTab label="Элемент">
        <TextInput label="Заголовок" validate={required()} source="title" />
        <TextInput
          label="Цвет (например, #BB6AB6)"
          validate={required()}
          source="hexColor"
        />
        <TextInput
          label="Alias ссылки рубрики"
          validate={required()}
          source="link"
        />
        <ReferenceInput
          validate={required()}
          fullWidth
          source="articlesPageId"
          reference="ArticlesPage"
          label="Раздел рубрики"
          filter={{ isSelect: true }}
        >
          <SelectInput
            label="Раздел рубрики"
            optionText="title"
            validate={required()}
          />
        </ReferenceInput>
        <RichTextInput
          label="Описание рубрики перед формой обратной связи"
          source="feedbackDescription"
          fullWidth
          multiline
        />
      </FormTab>
      <FormTab label="SEO">
        <TextInput
          label="Title в <head>"
          validate={required()}
          source="headTitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Description в <head>"
          validate={required()}
          fullWidth
          multiline
          source="headDescription"
        />
        <TextInput
          label="Заголовок h1"
          validate={required()}
          source="h1"
          fullWidth
          multiline
        />
      </FormTab>
    </TabbedForm>
  </Create>
);
