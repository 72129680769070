import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TabbedForm,
  TextInput,
  Create,
  required,
  ImageField,
  ImageInput,
  BooleanInput,
  Labeled,
  Toolbar,
  SaveButton,
  DeleteButton,
  FormTab,
} from "react-admin";
import ListActions from "../core/ListActions";

const ArticlePageFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
];

const ArticlePageTitle = ({ record }) => {
  return <span>Раздел статей {record ? `"${record.title}"` : ""}</span>;
};

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

export const ArticlePageList = () => (
  <List filters={ArticlePageFilters} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок" source="title" sortable={false} />
      <TextField
        label="Название раздела"
        source="sectionName"
        sortable={false}
      />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const ArticlePageEdit = (props) => (
  <Edit {...props} title={<ArticlePageTitle />}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="Элемент">
        <TextInput disabled source="id" />
        <TextInput label="Заголовок" validate={required()} source="title" />
        <TextInput
          label="Подзаголовок"
          validate={required()}
          source="subtitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Заголовок формы обратной связи"
          validate={required()}
          source="feedbackTitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Подзаголовок формы обратной связи"
          validate={required()}
          source="feedbackSubtitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Название раздела"
          validate={required()}
          source="sectionName"
        />
        <TextInput label="Alias ссылки" validate={required()} source="link" />
        <BooleanInput
          label='Этот раздел выводится в "Новости" на главной'
          source="isNews"
        />
      </FormTab>
      <FormTab label="SEO">
        <TextInput
          label="Микроразметка. Заголовок"
          validate={required()}
          source="seoTitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Микроразметка. Описание"
          validate={required()}
          source="seoDescription"
          fullWidth
          multiline
        />
        <Labeled label="Микроразметка. Изображение">
          <ImageField source="seoImage" />
        </Labeled>
        <ImageInput
          label="Изменить изображение в микроразметке"
          source="seoImage"
          accept="image/*"
        >
          <ImageField source="seoImage" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ArticlePageCreate = (props) => {
  return (
    <Create {...props} title="Создать раздел статей">
      <TabbedForm toolbar={<CreateToolbar />}>
        <FormTab label="Элемент">
          <TextInput label="Заголовок" validate={required()} source="title" />
          <TextInput
            label="Подзаголовок"
            validate={required()}
            source="subtitle"
            fullWidth
            multiline
          />
          <TextInput
            label="Заголовок формы обратной связи"
            validate={required()}
            source="feedbackTitle"
            fullWidth
            multiline
          />
          <TextInput
            label="Подзаголовок формы обратной связи"
            validate={required()}
            source="feedbackSubtitle"
            fullWidth
            multiline
          />
          <TextInput
            label="Название раздела"
            validate={required()}
            source="sectionName"
          />
          <TextInput label="Alias ссылки" validate={required()} source="link" />
          <BooleanInput
            label='Этот раздел выводится в "Новости" на главной'
            source="isNews"
          />
        </FormTab>
        <FormTab label="SEO">
          <TextInput
            label="Микроразметка. Заголовок"
            validate={required()}
            source="seoTitle"
            fullWidth
            multiline
          />
          <TextInput
            label="Микроразметка. Описание"
            validate={required()}
            source="seoDescription"
            fullWidth
            multiline
          />
          <ImageInput
            label="Микроразметка. Изображение"
            source="seoImage"
            accept="image/*"
          >
            <ImageField source="seoImage" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
