/* eslint-disable import/no-anonymous-default-export */
import { fetchUtils } from "react-admin";
import { apiUrl, domainMainSite } from "./apiCommon";
import axios from "axios";

const client = axios.create({
  withCredentials: true,
  baseURL: window.webRoot,
});

const token =
  window.location.hostname == "admin.xamarin.ru"
    ? ""
    : "vk1.a.lFYnfv17jh8qOi1XBPgpNEUv997y7dGLhHZ4RyJQQSQVg-7ayw4tDTdeScOkVE-hpUe8kswE4iBq0gyD7CSCuBxI1-wg-nXMmYzfK7Abe6JS7yTINW3BQolqNjUrw2JZF_OQN4kDlTtwrCr4wDJaMcBNOIuftlpXv53Dn2ktNjssvc_EjLyDqejpegL2IgjfQ-yJtbSRMTsqHk4Qua4N3g";

const groupID =
  window.location.hostname == "admin.xamarin.ru" ? "43356629" : "218867243";

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("Bearer");
  options.headers.set("Authorization", `${token}`);
  return fetchUtils.fetchJson(url, options);
};

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const convertCreateAllImages = (resource, params) => {
  return Promise.all(params.data.images.map(convertFileToBase64)).then(
    (transformedNewPictures) => {
      params.data.images = transformedNewPictures;
      if (params.data.seoImage) {
        return convertCreateSeoImage(resource, params);
      }
      return createMethod(resource, params);
    }
  );
};

const convertUpdateAllImages = (resource, params) => {
  return Promise.all(params.data.images.map(convertFileToBase64)).then(
    (transformedNewPictures) => {
      params.data.images = transformedNewPictures;
      if (params.data.seoImage?.seoImage) {
        return convertUpdateSeoImage(resource, params);
      }
      return updateMethod(resource, params);
    }
  );
};

const convertCreateSeoImage = (resource, params) => {
  return Promise.all([params.data.seoImage].map(convertFileToBase64)).then(
    (transformedNewPictures) => {
      params.data.seoImage = transformedNewPictures.toString();
      return createMethod(resource, params);
    }
  );
};

const convertUpdateSeoImage = (resource, params) => {
  return Promise.all([params.data.seoImage].map(convertFileToBase64)).then(
    (transformedNewPictures) => {
      params.data.seoImage = transformedNewPictures.toString();
      return updateMethod(resource, params);
    }
  );
};

const createMethod = (resource, params) => {
  if (
    resource == "Article" &&
    params.data.showOnSite &&
    params.data.publishVK
  ) {
    return httpClient(
      `${apiUrl}/ArticlesPage/GetById/${params.data.articlesPageId}`
    ).then(({ json }) => {
      const articlesPageLink = json.link;
      return httpClient(
        `${apiUrl}/Rubric/GetById/${params.data.rubricId}`
      ).then(({ json }) => {
        const rubricLink = json.link;

        params.data.attachment = `${domainMainSite}/${articlesPageLink}/${rubricLink}/${params.data.link}/`;

        return httpClient(`${apiUrl}/${resource}/Add`, {
          method: "POST",
          body: JSON.stringify(params.data),
        }).then(({ json }) => {
          return {
            data: { ...params.data, id: json.id },
          };
        });
      });
    });
  }
  return httpClient(`${apiUrl}/${resource}/Add`, {
    method: "POST",
    body: JSON.stringify(params.data),
  }).then(({ json }) => {
    return {
      data: { ...params.data, id: json.id },
    };
  });
};

const updateMethod = (resource, params) => {
  if (
    resource == "Article" &&
    params.data.showOnSite &&
    params.data.publishVK
  ) {
    return httpClient(
      `${apiUrl}/ArticlesPage/GetById/${params.data.articlesPageId}`
    ).then(({ json }) => {
      const articlesPageLink = json.link;
      return httpClient(
        `${apiUrl}/Rubric/GetById/${params.data.rubricId}`
      ).then(({ json }) => {
        const rubricLink = json.link;

        params.data.attachment = `${domainMainSite}/${articlesPageLink}/${rubricLink}/${params.data.link}/`;

        return httpClient(`${apiUrl}/${resource}/Update`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }).then(({ json }) => {
          return { data: json };
        });
      });
    });
  }
  return httpClient(`${apiUrl}/${resource}/Update`, {
    method: "PUT",
    body: JSON.stringify(params.data),
  }).then(({ json }) => {
    return { data: json };
  });
};

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;

    if (params.filter.articlePageId) {
      return httpClient(
        `${apiUrl}/${resource}/GetByArticlesPage/${params.filter.articlePageId}`
      ).then(({ json }) => ({
        data: json,
        total: json.length,
      }));
    }

    if (params.filter.rubricId) {
      return httpClient(
        `${apiUrl}/${resource}/GetByRubric/${params.filter.rubricId}`
      ).then(({ json }) => ({
        data: json,
        total: json.length,
      }));
    }

    return httpClient(
      `${apiUrl}/${resource}/GetAll`,
      !params.filter.isSelect
        ? {
            method: "POST",
            body: JSON.stringify({
              page: page,
              size: perPage,
              search: {
                isDeleted: params.filter.isDel ? true : false,
                rubricTitle: params.filter.rubricTitle,
              },
            }),
          }
        : { method: "POST", body: JSON.stringify({ search: {} }) }
    ).then(({ json }) => ({
      data: json.models,
      total: json.totalCount,
    }));
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/GetById/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    ),

  getMany: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/GetAll`, {
      method: "POST",
      body: JSON.stringify({ search: {} }),
    }).then(({ json }) => ({
      data: json.models,
      total: json.totalCount,
    }));
  },

  update: (resource, params) => {
    if (params.data.image?.image) {
      return Promise.all([params.data.image].map(convertFileToBase64)).then(
        (transformedNewPictures) => {
          params.data.image = transformedNewPictures.toString();
          return updateMethod(resource, params);
        }
      );
    }
    if (params.data.quoteImage?.quoteImage) {
      return Promise.all(
        [params.data.quoteImage].map(convertFileToBase64)
      ).then((transformedNewPictures) => {
        params.data.quoteImage = transformedNewPictures.toString();
        return updateMethod(resource, params);
      });
    }
    if (params.data.mainImage?.mainImage) {
      return Promise.all([params.data.mainImage].map(convertFileToBase64)).then(
        (transformedNewPictures) => {
          params.data.mainImage = transformedNewPictures.toString();
          if (params.data.images.some((i) => i.images)) {
            params.data.images = params.data.images.filter((i) => i.images);
            return convertUpdateAllImages(resource, params);
          }
          if (params.data.seoImage?.seoImage) {
            return convertUpdateSeoImage(resource, params);
          }
          return updateMethod(resource, params);
        }
      );
    }
    if (params.data.seoImage?.seoImage) {
      return Promise.all([params.data.seoImage].map(convertFileToBase64)).then(
        (transformedNewPictures) => {
          params.data.seoImage = transformedNewPictures.toString();
          if (params.data.images.some((i) => i.images)) {
            params.data.images = params.data.images.filter((i) => i.images);
            return convertUpdateAllImages(resource, params);
          }
          return updateMethod(resource, params);
        }
      );
    }
    if (params.data.images?.some((i) => i.images)) {
      params.data.images = params.data.images.filter((i) => i.images);
      return convertUpdateAllImages(resource, params);
    }
    return updateMethod(resource, params);
  },

  create: (resource, params) => {
    if (params.data.image) {
      return Promise.all([params.data.image].map(convertFileToBase64)).then(
        (transformedNewPictures) => {
          params.data.image = transformedNewPictures.toString();
          return createMethod(resource, params);
        }
      );
    }
    if (params.data.mainImage) {
      console.log(params.data);
      return Promise.all([params.data.mainImage].map(convertFileToBase64)).then(
        (transformedNewPictures) => {
          params.data.mainImage = transformedNewPictures.toString();
          if (params.data.images) {
            return convertCreateAllImages(resource, params);
          }
          return createMethod(resource, params);
        }
      );
    }
    if (params.data.seoImage) {
      return Promise.all([params.data.seoImage].map(convertFileToBase64)).then(
        (transformedNewPictures) => {
          params.data.seoImage = transformedNewPictures.toString();
          if (params.data.images) {
            return convertCreateAllImages(resource, params);
          }
          return createMethod(resource, params);
        }
      );
    }
    return createMethod(resource, params);
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/Remove/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: params.id })),
};
