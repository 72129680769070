import * as React from "react";
import { Admin, Resource } from "react-admin";
import {
  MainPageList,
  MainPageEdit,
  MainPageCreate,
} from "./Entities/MainPage";
import {
  MainBlockList,
  MainBlockEdit,
  MainBlockCreate,
} from "./Entities/MainBlocks";
import dataProvider from "../Providers/dataProvider";
import authProvider from "../Providers/authProvider";
import {
  AdvantageList,
  AdvantageEdit,
  AdvantageCreate,
} from "./Entities/Advantages";
import { ArticleList, ArticleEdit, ArticleCreate } from "./Entities/Articles";
import { RubricList, RubricEdit, RubricCreate } from "./Entities/Rubrics";
import {
  ArticlePageList,
  ArticlePageEdit,
  ArticlePageCreate,
} from "./Entities/ArticlePage";
import {
  GeneralPageList,
  GeneralPageEdit,
  GeneralPageCreate,
} from "./Entities/GeneralPage";
import { ProjectList, ProjectEdit, ProjectCreate } from "./Entities/Projects";
import {
  LinkTypeList,
  LinkTypeEdit,
  LinkTypeCreate,
} from "./Entities/LinkType";
import {
  IndustryList,
  IndustryEdit,
  IndustryCreate,
} from "./Entities/Industry";
import {
  QuestionList,
  QuestionEdit,
  QuestionCreate,
} from "./Entities/Question";
import { PolicyList, PolicyEdit, PolicyCreate } from "./Entities/Policies";
import { RobotsList, RobotsEdit, RobotsCreate } from "./Entities/Robots";
import { CompanyList, CompanyEdit, CompanyCreate } from "./Entities/Company";
import {
  CompanyBlockList,
  CompanyBlockEdit,
  CompanyBlockCreate,
} from "./Entities/CompanyBlock";

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource
      name="MainPage"
      list={MainPageList}
      edit={MainPageEdit}
      create={MainPageCreate}
      options={{ label: "Структура главной" }}
    />
    <Resource
      name="MainBlock"
      list={MainBlockList}
      edit={MainBlockEdit}
      create={MainBlockCreate}
      options={{ label: "Блоки контента на главной" }}
    />
    <Resource
      name="Advantage"
      list={AdvantageList}
      edit={AdvantageEdit}
      create={AdvantageCreate}
      options={{ label: "Плитки преимуществ" }}
    />
    <Resource
      name="Article"
      list={ArticleList}
      edit={ArticleEdit}
      create={ArticleCreate}
      options={{ label: "Статьи" }}
    />
    <Resource
      name="Rubric"
      list={RubricList}
      edit={RubricEdit}
      create={RubricCreate}
      options={{ label: "Рубрики" }}
    />
    <Resource
      name="ArticlesPage"
      list={ArticlePageList}
      edit={ArticlePageEdit}
      create={ArticlePageCreate}
      options={{ label: "Разделы статей" }}
    />
    <Resource
      name="GeneralPage"
      list={GeneralPageList}
      edit={GeneralPageEdit}
      create={GeneralPageCreate}
      options={{ label: "Все страницы сайта" }}
    />
    <Resource
      name="Project"
      list={ProjectList}
      edit={ProjectEdit}
      create={ProjectCreate}
      options={{ label: "Реализованные проекты" }}
    />
    <Resource
      name="ProjectLinkType"
      list={LinkTypeList}
      edit={LinkTypeEdit}
      create={LinkTypeCreate}
      options={{ label: "Маркеты (для ссылок)" }}
    />
    <Resource
      name="Industry"
      list={IndustryList}
      edit={IndustryEdit}
      create={IndustryCreate}
      options={{ label: "Сферы" }}
    />
    <Resource
      name="Faq"
      list={QuestionList}
      edit={QuestionEdit}
      create={QuestionCreate}
      options={{ label: "F.A.Q." }}
    />
    <Resource
      name="Policy"
      list={PolicyList}
      edit={PolicyEdit}
      create={PolicyCreate}
      options={{ label: "Политики" }}
    />
    <Resource
      name="RobotsTxt"
      list={RobotsList}
      edit={RobotsEdit}
      create={RobotsCreate}
      options={{ label: "Robots.txt" }}
    />
    <Resource
      name="AboutPage"
      list={CompanyList}
      edit={CompanyEdit}
      create={CompanyCreate}
      options={{ label: "О проекте (about)" }}
    />
    {/* <Resource
      name="CompanyBlock"
      list={CompanyBlockList}
      edit={CompanyBlockEdit}
      create={CompanyBlockCreate}
      options={{ label: "Company Blocks" }}
    /> */}
  </Admin>
);

export default App;
