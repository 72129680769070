import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SelectArrayInput,
  ReferenceArrayInput,
  required,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import ListActions from "../core/ListActions";

const MainBlockFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
];

const MainBlockTitle = ({ record }) => {
  return (
    <span>Блок контента на главной {record ? `"${record.title}"` : ""}</span>
  );
};

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

export const MainBlockList = (props) => (
  <List filters={MainBlockFilters} {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок" source="title" sortable={false} />
      <TextField label="Описание" source="description" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const MainBlockEdit = (props) => (
  <Edit {...props} title={<MainBlockTitle />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput label="Заголовок" validate={required()} source="title" />
      <TextInput
        validate={required()}
        multiline
        source="description"
        label="Описание"
        fullWidth
      />
      <ReferenceArrayInput
        validate={required()}
        fullWidth
        source="articleIds"
        reference="Article"
        filter={{ isSelect: true }}
      >
        <SelectArrayInput label="Отображать статьи" optionText="title" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const MainBlockCreate = (props) => (
  <Create {...props} title="Create MainBlock">
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput label="Заголовок" validate={required()} source="title" />
      <TextInput
        validate={required()}
        multiline
        source="description"
        label="Описание"
        fullWidth
      />
      <ReferenceArrayInput
        validate={required()}
        fullWidth
        source="articleIds"
        reference="Article"
        filter={{ isSelect: true }}
      >
        <SelectArrayInput label="Отображать статьи" optionText="title" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
