import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TabbedForm,
  TextInput,
  Create,
  required,
  ImageField,
  ImageInput,
  SaveButton,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  Labeled,
  FormTab,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const CompanyTitle = ({ record }) => {
  return <span>О проекте (about) {record ? `"${record.title}"` : ""}</span>;
};

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

export const CompanyList = () => (
  <List actions={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок верхний" source="title" sortable={false} />
      <TextField
        label="Подзаголовок верхний"
        source="subtitle"
        sortable={false}
      />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const CompanyEdit = (props) => (
  <Edit {...props} title={<CompanyTitle />}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="Элемент">
        <TextInput disabled source="id" />
        <TextInput
          label="Заголовок верхний"
          validate={required()}
          source="title"
        />
        <TextInput
          label="Подзаголовок верхний"
          validate={required()}
          source="subtitle"
          fullWidth
        />
        <TextInput
          label="Заголовок средний"
          validate={required()}
          source="offerTitle"
        />
        <TextInput
          label="Подзаголвок средний"
          validate={required()}
          source="offerSubtitle"
          fullWidth
        />
        <TextInput
          label="Заголовок Истории компании"
          validate={required()}
          source="historyTitle"
        />
        <RichTextInput
          validate={required()}
          source="historyDescription"
          fullWidth
          label="Описание истории компании"
        />
        <TextInput
          label="Цитата. Текст цитаты"
          validate={required()}
          source="quoteDescription"
          fullWidth
        />
        <TextInput
          label="Цитата. Автор"
          validate={required()}
          source="quoteSign"
        />
        <Labeled label="Цитата. Изображение">
          <ImageField source="quoteImage" />
        </Labeled>
        <ImageInput
          label="Изменить изображение цитаты"
          source="quoteImage"
          accept="image/*"
        >
          <ImageField source="quoteImage" />
        </ImageInput>
        <ReferenceArrayInput
          validate={required()}
          fullWidth
          source="advantageIds"
          reference="Advantage"
          label="Advantages"
          filter={{ isSelect: true }}
        >
          <SelectArrayInput label="Преимущества" optionText="title" />
        </ReferenceArrayInput>
      </FormTab>
      {/* <ReferenceArrayInput
        validate={required()}
        fullWidth
        source="companyBlockIds"
        reference="CompanyBlock"
        label="Company Blocks"
        filter={{ isSelect: true }}
      >
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput> */}
    </TabbedForm>
  </Edit>
);

export const CompanyCreate = (props) => {
  return (
    <Create {...props} title="Create Company">
      <SimpleForm>
        <TextInput validate={required()} source="title" />
        <TextInput validate={required()} source="subtitle" fullWidth />
        <TextInput validate={required()} source="seoTitle" />
        <TextInput validate={required()} source="seoDescription" fullWidth />
        <ImageInput source="seoImage" accept="image/*">
          <ImageField source="seoImage" />
        </ImageInput>
        <TextInput validate={required()} source="feedbackTitle" />
        <TextInput validate={required()} source="feedbackSubtitle" fullWidth />
        <TextInput validate={required()} source="sectionName" />
      </SimpleForm>
    </Create>
  );
};
