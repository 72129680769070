import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  Toolbar,
  SaveButton,
} from "react-admin";
import { useParams } from "react-router-dom";

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const RobotsTitle = ({ record }) => {
  return <span>Robots.txt {record ? `"${record.title}"` : ""}</span>;
};

const Common = (props) => {
  const { id } = useParams();

  return (
    <SimpleForm toolbar={<EditToolbar />} {...props}>
      {id && <TextInput disabled source="id" />}
      <TextInput multiline validate={required()} source="content" fullWidth />
    </SimpleForm>
  );
};

export const RobotsList = (props) => (
  <List {...props} actions={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="content" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const RobotsEdit = (props) => (
  <Edit {...props} title={<RobotsTitle />}>
    <Common />
  </Edit>
);

export const RobotsCreate = (props) => (
  <Create {...props} title="Create Robots">
    <Common />
  </Create>
);
