import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TabbedForm,
  TextInput,
  Create,
  SelectArrayInput,
  ReferenceArrayInput,
  required,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  useGetOne,
  BooleanInput,
  useRecordContext,
  maxLength,
  Labeled,
  Toolbar,
  SaveButton,
  DeleteButton,
  FormTab,
  DateTimeInput,
  BooleanField,
  DateField,
  NumberInput,
} from "react-admin";
import ListActions from "../core/ListActions";
import { httpClient } from "../../Providers/dataProvider";
import { apiUrl, domainMainSite } from "../../Providers/apiCommon";
import { useParams } from "react-router-dom";
import { RichTextEditor } from "@mantine/rte";
import { useController } from "react-hook-form";
import { Controlled as ControlledEditor } from "react-codemirror2";
import { Button } from "@mui/material";
import "codemirror/mode/css/css";
import "codemirror/mode/xml/xml";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";

const ArticleFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
  <TextInput source="rubricTitle" label="Рубрика" alwaysOn />,
];

const ArticleTitle = ({ record }) => {
  return <span>Статья {record ? `"${record.title}"` : ""}</span>;
};

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const CustomRichTextInput = ({ isCreate }) => {
  const record = useController({ name: "description" });

  const [isLoading, setIsLoading] = useState(true);

  const [isHtml, setIsHtml] = useState(false);

  const value = record.field.value;

  const handleChange = (editor, data, value) => {
    record.field.onChange(value);
  };
  const content = useController({ name: "tableContents" }).field.value;

  useEffect(() => {
    if (!isCreate && content && content.length) {
      const tooltip = document.querySelector(".ql-tooltip");
      if (tooltip != null && !document.querySelector(".select")) {
        var select = document.createElement("select");
        select.classList.add("select");

        var firstOption = document.createElement("option");
        firstOption.textContent = "Выберите ссылку якорь";
        firstOption.value = "";
        firstOption.selected = "selected";
        select.appendChild(firstOption);

        content.forEach((c) => {
          var option = document.createElement("option");
          option.textContent = c.title;
          option.value = "#" + c.id;
          select.appendChild(option);
        });

        select.onchange = (e) => {
          console.log(e.target.value);
          var input = tooltip.querySelector("input");

          if (input != null && e.target.value) {
            input.value = e.target.value;
          }
        };

        tooltip.appendChild(select);
      }
    }
  }, [document.querySelector(".ql-tooltip"), isLoading]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
  }, [isLoading]);

  if (!record) return null;
  return (
    <>
      <AnchorLinkNode />
      <Button
        style={{
          width: 200,
          marginBottom: "20px",
          marginTop: "10px",
          textTransform: "inherit",
        }}
        onClick={(e) => {
          e.preventDefault();
          setIsHtml(isHtml ? false : true);
          if (isHtml) {
            setIsLoading(false);
          }
        }}
        variant="outlined"
        size="small"
        color="inherit"
      >
        Показать {isHtml ? "текст" : "HTML - код"}
      </Button>
      {record.field.value != "<p><br></p>" && isLoading && (
        <>
          {!isHtml && (
            <RichTextEditor
              style={{ width: "100%", height: "400px", overflowY: "scroll" }}
              {...record.field}
            />
          )}
        </>
      )}
      {record.field.value == "<p><br></p>" && isLoading && (
        <>
          {!isHtml && (
            <RichTextEditor
              style={{ width: "100%", height: "400px", overflowY: "scroll" }}
              {...record.field}
            />
          )}
        </>
      )}
      {isHtml && (
        <div
          style={
            {
              // maxWidth: "800px",
            }
          }
        >
          <ControlledEditor
            onBeforeChange={handleChange}
            value={value}
            className="code-mirror-wrapper"
            options={{
              lineWrapping: true,
              lint: true,
              mode: "xml",
              theme: "material",
              lineNumbers: true,
            }}
          />
        </div>
      )}
    </>
  );
};

const CustomNode = () => {
  const record = useController({ name: "articlesPageId" });
  if (!record) return null;
  return (
    <>
      <ReferenceInput
        validate={required()}
        fullWidth
        source="articlesPageId"
        reference="ArticlesPage"
        label="Раздел"
        filter={{ isSelect: true }}
      >
        <SelectInput label="Раздел" optionText="title" validate={required()} />
      </ReferenceInput>
      <ReferenceInput
        validate={required()}
        fullWidth
        source="rubricId"
        reference="Rubric"
        label="Рубрика"
        filter={{ isSelect: true, articlePageId: record.field.value }}
        disabled={!record.field.value}
      >
        <SelectInput
          label="Рубрика"
          optionText="title"
          disabled={!record.field.value}
          validate={required()}
        />
      </ReferenceInput>
    </>
  );
};

const AnchorLinkNode = () => {
  const articlesPage = useController({ name: "articlesPageIdForLink" });
  const rubric = useController({ name: "rubricIdForLink" });
  const article = useController({ name: "articleIdForLink" });
  const header = useController({ name: "header" });

  const [contentHeaders, setContentHeaders] = useState([]);
  const [text, setText] = useState("");
  const [articleLink, setArticleLink] = useState("");
  const [isStartChoiceHeader, setIsStartChoiceHeader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (article.field.value) {
      setIsLoading(false);
      httpClient(`${apiUrl}/Article/GetById/${article.field.value}`).then(
        ({ json }) => {
          setArticleLink(json.link);
          setContentHeaders(json.tableContents);
          setIsLoading(true);
        }
      );
    }
  }, [article.field.value]);

  useEffect(() => {
    rubric.field.onChange(0);
  }, [articlesPage.field.value]);

  useEffect(() => {
    article.field.onChange(0);
  }, [rubric.field.value]);

  useEffect(() => {
    header.field.onChange(0);
  }, [article.field.value]);

  useEffect(() => {
    if (header.field.value) {
      httpClient(
        `${apiUrl}/ArticlesPage/GetById/${articlesPage.field.value}`
      ).then(({ json }) => {
        const articlesPageLink = json.link;
        httpClient(`${apiUrl}/Rubric/GetById/${rubric.field.value}`).then(
          ({ json }) => {
            const rubricLink = json.link;
            setText(
              `${domainMainSite}/${articlesPageLink}/${rubricLink}/${articleLink}/#${header.field.value}`
            );
          }
        );
      });
    }
  }, [header.field.value]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p>Сформировать якорную ссылку из другой статьи:</p>
        {!isStartChoiceHeader && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setIsStartChoiceHeader(true);
            }}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              height: "50%",
              textTransform: "inherit",
            }}
            variant="outlined"
            size="small"
          >
            Начать генерацию ссылки
          </Button>
        )}
      </div>
      {isStartChoiceHeader && (
        <ReferenceInput
          fullWidth
          source="articlesPageIdForLink"
          reference="ArticlesPage"
          label="Выберите раздел"
        >
          <SelectInput
            label="Выберите раздел"
            optionText="title"
            defaultValue={0}
          />
        </ReferenceInput>
      )}

      {!!articlesPage.field.value && (
        <ReferenceInput
          fullWidth
          source="rubricIdForLink"
          reference="Rubric"
          label="Выберите рубрику"
          disabled={!articlesPage.field.value}
          filter={{ isSelect: true, articlePageId: articlesPage.field.value }}
        >
          <SelectInput
            label="Выберите рубрику"
            optionText="title"
            disabled={!articlesPage.field.value}
            defaultValue={0}
            style={{ marginLeft: "20px" }}
          />
        </ReferenceInput>
      )}
      {!!rubric.field.value && (
        <ReferenceInput
          fullWidth
          source="articleIdForLink"
          reference="Article"
          label="Выберите статью"
          disabled={!rubric.field.value}
          filter={{ isSelect: true, rubricId: rubric.field.value }}
        >
          <SelectInput
            label="Выберите статью"
            optionText="title"
            disabled={!rubric.field.value}
            defaultValue={0}
            style={{ marginLeft: "20px" }}
          />
        </ReferenceInput>
      )}
      {!!article.field.value &&
        isLoading &&
        (contentHeaders.length ? (
          <SelectInput
            label="Выберите заголовок"
            source="header"
            choices={[
              ...contentHeaders.map((h) => {
                return { id: h.id, name: h.title };
              }),
            ]}
            defaultValue={0}
            style={{ marginLeft: "20px" }}
          />
        ) : (
          <p style={{ marginLeft: "20px", display: "inline-block" }}>
            У данной статьи нет заголовков
          </p>
        ))}
      {!!header.field.value && (
        <p style={{ marginTop: 0 }}>
          <span style={{ fontWeight: "600" }}>Ваша якорная ссылка:</span> {text}
          <button
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard
                .writeText(text)
                .then(() => {
                  console.log("Text copied to clipboard");
                })
                .catch((err) => {
                  console.error("Error in copying text: ", err);
                });
            }}
            style={{ marginLeft: "20px", cursor: "pointer" }}
          >
            Копировать в буфер
          </button>
        </p>
      )}
      {isStartChoiceHeader && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setIsStartChoiceHeader(false);
            rubric.field.onChange(0);
            article.field.onChange(0);
            articlesPage.field.onChange(0);
            header.field.onChange(0);
          }}
          style={{
            cursor: "pointer",
            height: "50%",
            textTransform: "inherit",
            display: "block",
          }}
          variant="outlined"
          size="small"
        >
          Скрыть/закончить выбор ссылки
        </Button>
      )}
    </div>
  );
};

const CustomNodeDate = () => {
  const record = useController({ name: "showOnSite" });
  if (!record) return null;
  if (!record.field.value) return <></>;
  return (
    <>
      <DateTimeInput
        label="Начать отображать с"
        source="publicationStartDate"
      />
      <DateTimeInput label="Снять с публикации" source="publicationEndDate" />
    </>
  );
};

const CustomVKText = () => {
  const isPublic = useController({ name: "publishVK" });

  const showOnSite = useController({ name: "showOnSite" });
  return (
    <>
      {isPublic.field.value && showOnSite.field.value && (
        <TextInput
          fullWidth
          multiline
          label="Текст в посте в группе VK"
          source="message"
        />
      )}
    </>
  );
};

const CustomVKBoolean = ({ isIncluded }) => {
  const showOnSite = useController({ name: "showOnSite" });
  const isPublishVK = useController({ name: "isPublishedVK" });
  const publishVKDate = useController({ name: "vkPublicationDate" });

  return (
    <>
      {showOnSite.field.value && (
        <>
          {isPublishVK.field.value && (
            <p>
              Последний пост опубликован -{" "}
              {new Date(publishVKDate.field.value).toLocaleString("ru", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              })}
            </p>
          )}
          <>
            <BooleanInput
              label={
                isPublishVK.field.value
                  ? "Опубликовать повторно пост в группе VK"
                  : "Опубликовать пост в группе VK"
              }
              source="publishVK"
              defaultValue={isIncluded}
            />
          </>
        </>
      )}
    </>
  );
};

export const ArticleList = () => (
  <List filters={ArticleFilters} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок" source="title" sortable={false} />
      <TextField label="Alias" source="link" sortable={false} />
      <BooleanField source="showOnSite" label="Показывается на сайте" />
      <DateField
        showTime
        source="publicationStartDate"
        label="Дата публикации"
      />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const ArticleEdit = (props) => {
  const { id } = useParams();

  const { data } = useGetOne("Article", { id: id });

  return (
    <Edit {...props} title={<ArticleTitle />}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="Элемент">
          <TextInput disabled source="id" />
          <TextInput label="Заголовок" validate={required()} source="title" />
          <TextInput
            validate={[required(), maxLength(150, "Максимум 150 символов")]}
            source="subtitle"
            label="Анонс"
            fullWidth
          />
          <CustomNode />
          <Labeled label="Текст статьи">
            <CustomRichTextInput />
          </Labeled>
          {/* <div style={{ display: "flex" }}>
          {data?.images?.map((i, index) => {
            return <img key={index} src={i} width={200} />;
          })}
        </div>
        <ImageInput
          multiple
          label="Change images"
          source="images"
          accept="image/*"
        >
          <ImageField multiple source="images" sx={[{ img: { width: 200 } }]} />
        </ImageInput> */}
          <TextInput
            validate={required()}
            label="Заголовок формы обратной связи"
            source="feedbackTitle"
            fullWidth
            multiline
          />
          <TextInput
            validate={required()}
            label="Подзаголовок формы обратной связи"
            source="feedbackSubtitle"
            fullWidth
            multiline
          />
          <TextInput validate={required()} label="Alias ссылки" source="link" />
          <TextInput label="Источник статьи" fullWidth source="source" />
          <TextInput
            label="Заголовок источника статьи"
            fullWidth
            source="sourceTitle"
          />
          <BooleanInput
            label="Показывать на сайте"
            source="showOnSite"
            defaultChecked
          />
          <CustomNodeDate />
          <CustomVKBoolean isIncluded={false} />
          <CustomVKText />
          <NumberInput
            validate={required()}
            label="Порядок отображения"
            source="order"
          />
        </FormTab>
        <FormTab label="SEO">
          <TextInput
            fullWidth
            multiline
            source="seoTitle"
            label="Микроразметка. Заголовок"
          />
          <TextInput
            label="Микроразметка. Описание"
            source="seoDescription"
            fullWidth
            multiline
          />
          <TextInput
            label="Микроразметка. Ключевые слова"
            source="seoKeywords"
            fullWidth
            multiline
          />
          <TextInput label="Заголовок h1" source="h1" fullWidth />
          <Labeled label="Микроразметка. Изображение">
            <ImageField source="seoImage" />
          </Labeled>
          <ImageInput
            label="Изменить изображение в микроразметке"
            source="seoImage"
            accept="image/*"
          >
            <ImageField source="seoImage" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ArticleCreate = (props) => {
  return (
    <Create {...props} title="Создать статью">
      <TabbedForm toolbar={<CreateToolbar />}>
        <FormTab label="Элемент">
          <TextInput
            fullWidth
            multiline
            label="Заголовок"
            validate={required()}
            source="title"
          />
          <TextInput
            validate={[required(), maxLength(150, "Максимум 150 символов")]}
            source="subtitle"
            label="Анонс"
            fullWidth
          />
          <CustomNode />
          <Labeled label="Текст статьи">
            <CustomRichTextInput isCreate={true} />
          </Labeled>
          {/* <ImageInput multiple source="images" accept="image/*">
          <ImageField source="images" sx={[{ img: { width: 200 } }]} />
        </ImageInput> */}
          <TextInput
            validate={required()}
            label="Заголовок формы обратной связи"
            source="feedbackTitle"
            fullWidth
            multiline
          />
          <TextInput
            validate={required()}
            label="Подзаголовок формы обратной связи"
            source="feedbackSubtitle"
            fullWidth
            multiline
          />
          <TextInput label="Alias ссылки" validate={required()} source="link" />
          <TextInput label="Источник статьи" fullWidth source="source" />
          <TextInput
            label="Заголовок источника статьи"
            fullWidth
            source="sourceTitle"
          />
          <BooleanInput
            label="Показывать на сайте"
            source="showOnSite"
            defaultValue={true}
          />
          <CustomNodeDate />
          <CustomVKBoolean isIncluded={true} />
          <CustomVKText />
          <NumberInput
            validate={required()}
            label="Порядок отображения"
            source="order"
          />
        </FormTab>
        <FormTab label="SEO">
          <TextInput label="Микроразметка. Заголовок" source="seoTitle" />
          <TextInput
            label="Микроразметка. Описание"
            source="seoDescription"
            fullWidth
            multiline
          />
          <TextInput
            label="Микроразметка. Ключевые слова"
            source="seoKeywords"
            fullWidth
            multiline
          />
          <TextInput label="Заголовок h1" source="h1" fullWidth />
          <ImageInput
            label="Микроразметка. Изображение"
            source="seoImage"
            accept="image/*"
          >
            <ImageField source="seoImage" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
