import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import ListActions from "../core/ListActions";
import { RichTextInput } from "ra-input-rich-text";
import { useParams } from "react-router-dom";

const PolicyTitle = ({ record }) => {
  return <span>Политика {record ? `"${record.title}"` : ""}</span>;
};

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const Common = (props) => {
  const { id } = useParams();

  return (
    <SimpleForm {...props} toolbar={id ? <EditToolbar /> : <CreateToolbar />}>
      {id && <TextInput disabled source="id" />}
      <TextInput
        multiline
        fullWidth
        label="Заголовок"
        validate={required()}
        source="title"
      />
      <TextInput
        multiline
        fullWidth
        label="Описание"
        validate={required()}
        source="description"
      />
      <RichTextInput
        label="Текст Политики"
        validate={required()}
        source="content"
      />
      <TextInput label="Alias ссылки" validate={required()} source="link" />
    </SimpleForm>
  );
};

export const PolicyList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Заголовок" source="title" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const PolicyEdit = (props) => (
  <Edit {...props} title={<PolicyTitle />}>
    <Common />
  </Edit>
);

export const PolicyCreate = (props) => (
  <Create {...props} title="Создать политику">
    <Common />
  </Create>
);
