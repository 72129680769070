import * as React from "react";
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, ImageField, ImageInput, required, NumberInput, BooleanInput } from 'react-admin';
import ListActions from "../core/ListActions";

const CompanyBlockFilters = [
    <BooleanInput source="isDel" label="IsDeleted" alwaysOn />,
];

const CompanyBlockTitle = ({ record }) => {
    return <span>CompanyBlock {record ? `"${record.title}"` : ''}</span>
};

export const CompanyBlockList = props => (
    <List filters={CompanyBlockFilters} {...props} actions={<ListActions />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" sortable={false} />
            <TextField source="title" sortable={false} />
            <TextField label="Subtitle" source="subtitle" sortable={false} />
            <EditButton />
        </Datagrid>
    </List>
);

export const CompanyBlockEdit = props => (
    <Edit {...props} title={<CompanyBlockTitle />}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput validate={required()} source="title" />
            <TextInput validate={required()} source="subtitle" fullWidth />
            <ImageField source="image" />
            <ImageInput label="Change image" source="image" accept="image/*">
                <ImageField source="image" />
            </ImageInput>
            <TextInput validate={required()} source="link" />
        </SimpleForm>
    </Edit>
);

export const CompanyBlockCreate = props => (
    <Create {...props} title="Create CompanyBlock">
        <SimpleForm>
            <TextInput validate={required()} source="title" />
            <TextInput validate={required()} source="subtitle" fullWidth />
            <ImageInput validate={required()} source="image" accept="image/*">
                <ImageField source="image" />
            </ImageInput>
            <TextInput validate={required()} source="link" />
        </SimpleForm>
    </Create>
);