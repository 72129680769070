import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TabbedForm,
  TextInput,
  Create,
  ImageField,
  ImageInput,
  required,
  useGetOne,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  Labeled,
  Toolbar,
  SaveButton,
  DeleteButton,
  FormTab,
  NumberInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useParams } from "react-router-dom";
import ListActions from "../core/ListActions";

const ProjectFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
];

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const ProjectTitle = ({ record }) => {
  return <span>Реализованный проект {record ? `"${record.title}"` : ""}</span>;
};

export const ProjectList = (props) => (
  <List filters={ProjectFilters} {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Название проекта" source="title" sortable={false} />
      <TextField label="Подзаголовок" source="subtitle" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const ProjectEdit = (props) => {
  const { id } = useParams();

  const { data } = useGetOne("Project", { id: id });

  return (
    <Edit redirect={false} {...props} title={<ProjectTitle />}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="Элемент">
          <TextInput disabled source="id" />
          <TextInput
            label="Название проекта"
            validate={required()}
            source="title"
          />
          <TextInput
            label="Подзаголовок"
            validate={required()}
            source="subtitle"
            fullWidth
          />
          <TextInput
            label="Подзаголовок внутри карточки проекта"
            validate={required()}
            source="innerSubtitle"
            fullWidth
          />
          <RichTextInput
            label="Описание"
            validate={required()}
            source="description"
            fullWidth
          />
          <ReferenceInput
            validate={required()}
            fullWidth
            source="industryId"
            reference="Industry"
            filter={{ isSelect: true }}
          >
            <SelectInput label="Сфера" optionText="title" />
          </ReferenceInput>
          <Labeled label="Изображение на главной проекта">
            <ImageField source="mainImage" />
          </Labeled>
          <ImageInput
            label="Изменить изображение на главной проекта"
            source="mainImage"
            accept="image/*"
          >
            <ImageField source="mainImage" />
          </ImageInput>
          <label
            style={{
              fontFamily: "Roboto Helvetica Arial sans-serif",
              fontWeight: "400",
              fontSize: "1rem",
              lineHeight: "1.5",
              letterSpacing: "0.00938em",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Изображения внутри проекта
          </label>
          <div style={{ display: "flex" }}>
            {data?.images?.map((i, index) => {
              return <img key={index} src={i} />;
            })}
          </div>
          <ImageInput
            multiple
            label="Поменять изображения внутри проекта"
            source="images"
            accept="image/*"
          >
            <ImageField source="images" />
          </ImageInput>
          <ReferenceInput
            source="firstLinkType"
            reference="ProjectLinkType"
            filter={{ isSelect: true }}
          >
            <SelectInput
              validate={required()}
              optionText="title"
              label="Название источника №1 (маркет, сайт)"
            />
          </ReferenceInput>
          <TextInput label="Ссылка на первый источник" source="firstLinkUrl" />
          <ReferenceInput
            source="secondLinkType"
            reference="ProjectLinkType"
            filter={{ isSelect: true }}
          >
            <SelectInput
              validate={required()}
              optionText="title"
              label="Название источника №2 (маркет, сайт)"
            />
          </ReferenceInput>
          <TextInput label="Ссылка на второй источник" source="secondLinkUrl" />
          <ReferenceInput
            source="thirdLinkType"
            reference="ProjectLinkType"
            filter={{ isSelect: true }}
          >
            <SelectInput
              validate={required()}
              optionText="title"
              label="Название источника №3 (маркет, сайт)"
            />
          </ReferenceInput>
          <TextInput label="Ссылка на третий источник" source="thirdLinkUrl" />
          <TextInput label="Alias ссылки" validate={required()} source="link" />
          <NumberInput
            validate={required()}
            label="Порядок отображения"
            source="order"
          />
        </FormTab>
        <FormTab label="SEO">
          <TextInput
            fullWidth
            multiline
            label="Микроразметка. Заголовок"
            source="seoTitle"
          />
          <TextInput
            label="Микроразметка. Описание"
            source="seoDescription"
            fullWidth
            multiline
          />
          <TextInput
            label="Микроразметка. Ключевые слова"
            source="seoKeywords"
            fullWidth
            multiline
          />
          <TextInput multiline label="Заголовок h1" source="h1" fullWidth />
          <Labeled label="Микроразметка. Изображение">
            <ImageField source="seoImage" />
          </Labeled>
          <ImageInput
            label="Изменить изображение в микроразметке"
            source="seoImage"
            accept="image/*"
          >
            <ImageField source="seoImage" />
          </ImageInput>
          <TextInput
            label="Title в <head>"
            validate={required()}
            source="headTitle"
            fullWidth
            multiline
          />
          <TextInput
            label="Description в <head>"
            validate={required()}
            fullWidth
            multiline
            source="headDescription"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ProjectCreate = (props) => (
  <Create {...props} title="Создать реализованный проект">
    <TabbedForm toolbar={<CreateToolbar />}>
      <FormTab label="Элемент">
        <TextInput
          label="Название проекта"
          validate={required()}
          source="title"
        />
        <TextInput
          label="Подзаголовок"
          validate={required()}
          source="subtitle"
          fullWidth
        />
        <TextInput
          label="Подзаголовок внутри карточки проекта"
          validate={required()}
          source="innerSubtitle"
          fullWidth
        />
        <RichTextInput
          label="Описание"
          validate={required()}
          source="description"
          fullWidth
        />
        <ReferenceInput
          validate={required()}
          fullWidth
          source="industryId"
          reference="Industry"
          filter={{ isSelect: true }}
        >
          <SelectInput label="Сфера" optionText="title" />
        </ReferenceInput>
        <ImageInput
          label="Изображение на главной проекта"
          source="mainImage"
          accept="image/*"
        >
          <ImageField source="mainImage" />
        </ImageInput>
        <ImageInput
          multiple
          validate={required()}
          source="images"
          accept="image/*"
          label="Изображения внутри проекта"
        >
          <ImageField source="images" />
        </ImageInput>
        <ReferenceInput
          source="firstLinkType"
          reference="ProjectLinkType"
          filter={{ isSelect: true }}
        >
          <SelectInput
            validate={required()}
            optionText="title"
            defaultValue={1}
            label="Название источника №1 (маркет, сайт)"
          />
        </ReferenceInput>
        <TextInput label="Ссылка на первый источник" source="firstLinkUrl" />
        <ReferenceInput
          source="secondLinkType"
          reference="ProjectLinkType"
          filter={{ isSelect: true }}
        >
          <SelectInput
            validate={required()}
            optionText="title"
            defaultValue={2}
            label="Название источника №2 (маркет, сайт)"
          />
        </ReferenceInput>
        <TextInput label="Ссылка на второй источник" source="secondLinkUrl" />
        <ReferenceInput
          source="thirdLinkType"
          reference="ProjectLinkType"
          filter={{ isSelect: true }}
        >
          <SelectInput
            validate={required()}
            optionText="title"
            defaultValue={3}
            label="Название источника №3 (маркет, сайт)"
          />
        </ReferenceInput>
        <TextInput label="Ссылка на третий источник" source="thirdLinkUrl" />
        <TextInput label="Alias ссылки" validate={required()} source="link" />
        <NumberInput
          validate={required()}
          label="Порядок отображения"
          source="order"
        />
      </FormTab>
      <FormTab label="SEO">
        <TextInput
          fullWidth
          multiline
          label="Микроразметка. Заголовок"
          source="seoTitle"
        />
        <TextInput
          label="Микроразметка. Описание"
          source="seoDescription"
          fullWidth
          multiline
        />
        <TextInput
          label="Микроразметка. Ключевые слова"
          source="seoKeywords"
          fullWidth
          multiline
        />
        <TextInput fullWidth multiline label="Заголовок h1" source="h1" />
        <ImageInput
          label="Микроразметка. Изображение"
          source="seoImage"
          accept="image/*"
        >
          <ImageField source="seoImage" />
        </ImageInput>
        <TextInput
          label="Title в <head>"
          validate={required()}
          source="headTitle"
          fullWidth
          multiline
        />
        <TextInput
          label="Description в <head>"
          validate={required()}
          fullWidth
          source="headDescription"
          multiline
        />
      </FormTab>
    </TabbedForm>
  </Create>
);
