import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import ListActions from "../core/ListActions";

const IndustryFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
];

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const IndustryTitle = ({ record }) => {
  return <span>Сфера {record ? `"${record.title}"` : ""}</span>;
};

export const IndustryList = (props) => (
  <List filters={IndustryFilters} {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Название сферы" source="title" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const IndustryEdit = (props) => (
  <Edit {...props} title={<IndustryTitle />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput label="Название сферы" validate={required()} source="title" />
      <TextInput label="Alias ссылки" validate={required()} source="link" />
    </SimpleForm>
  </Edit>
);

export const IndustryCreate = (props) => (
  <Create {...props} title="Создать сферу">
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput label="Название сферы" validate={required()} source="title" />
      <TextInput label="Alias ссылки" validate={required()} source="link" />
    </SimpleForm>
  </Create>
);
