import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import ListActions from "../core/ListActions";

const LinkTypeFilters = [
  <BooleanInput source="isDel" label="Удаленное" alwaysOn />,
];

const EditToolbar = (props) => {
  return (
    <Toolbar style={{ justifyContent: "space-between" }} {...props}>
      <SaveButton label="Сохранить" />
      <DeleteButton label="Удалить" />
    </Toolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Сохранить" />
    </Toolbar>
  );
};

const LinkTypeTitle = ({ record }) => {
  return <span>Маркет {record ? `"${record.title}"` : ""}</span>;
};

export const LinkTypeList = (props) => (
  <List filters={LinkTypeFilters} {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField label="Название" source="title" sortable={false} />
      <EditButton label="Изменить" />
    </Datagrid>
  </List>
);

export const LinkTypeEdit = (props) => (
  <Edit {...props} title={<LinkTypeTitle />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput label="Название" validate={required()} source="title" />
    </SimpleForm>
  </Edit>
);

export const LinkTypeCreate = (props) => (
  <Create {...props} title="Создать маркет">
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput label="Название" validate={required()} source="title" />
    </SimpleForm>
  </Create>
);
